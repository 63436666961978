<template>
  <section class="deposit-modal" :class="platform">
    <div class="deposit-modal-wrapper" data-t="deposit-modal-mp3t">
      <button v-if="isMobile" class="close-button" @click="close">
        <StIcon name="cross-large" :size="20" />
      </button>
      <div class="reward">
        <h2 class="title">{{ t('bonuses.authModalTitle') }}</h2>
        <DepositBonusAwards
          size="xl"
          :awards="bonusesOutput"
          :is-step-bonus="isStepBonus"
          direction="column"
        />
        <i18n-t
          v-if="maxDepositAmount && !isMultiBonus"
          tag="p"
          keypath="bonuses.authMaxBonus"
          class="reward-max"
          data-t="reward-max-0prt"
          :data-reward-amount="maxDepositAmount.amount"
        >
          <template #amount>
            {{ maxDepositAmount.amount }}
            <StIcon
              v-if="maxDepositAmount.bonusCurrency?.icon"
              :size="isMobile ? 14 : 16"
              :name="maxDepositAmount.bonusCurrency.icon"
            />
          </template>
        </i18n-t>
      </div>

      <StButton
        class="deposit-button"
        data-t="deposit-button-9d7o"
        :size="isMobile ? 'l' : 'xl'"
        replace
        :to="{ query: { modal: 'payments', tab: 'deposit' } }"
      >
        {{ t('bonuses.makeDeposit') }}
      </StButton>

      <div class="timer">
        <BonusTimer
          v-if="favoriteNewDepositBonus?.expiredAt"
          :label="t('bonuses.depositExpiredAt')"
          :expires-at="favoriteNewDepositBonus.expiredAt"
          is-shown-days-label
          data-t="bonus-timer"
        />
      </div>
    </div>
  </section>
</template>

<script setup lang="ts">
import BonusTimer from '../FreeBonusCard/parts/BonusTimer.vue'
import { useDepositBonusesStore } from '../../stores/useDepositBonusesStore'
import { useDepositBonus } from '../../composables/useDepositBonus'
import DepositBonusAwards from '../DepositBonusAwards/DepositBonusAwards.vue'

const { t } = useI18n()
const { platform, isMobile } = usePlatform()

const { favoriteNewDepositBonus } = storeToRefs(useDepositBonusesStore())
const { maxDepositAmount, bonusesOutput, isMultiBonus, isStepBonus } =
  useDepositBonus(favoriteNewDepositBonus)

const router = useRouter()
function close() {
  router.replace({ query: {} })
}
</script>

<style scoped>
.deposit-modal-wrapper {
  position: relative;

  display: flex;
  flex-direction: column;

  width: 100%;
  padding: var(--spacing-300);
  padding-top: 164px; /* stylelint-disable-line */

  background-image: url('./assets/bg.png');
  background-repeat: no-repeat;
  background-position: top center;
  background-size: contain;
}

.title {
  margin: 0;
  font: var(--desktop-text-lg-semibold);
  color: var(--text-primary);
  text-align: center;
}

.reward {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.reward-max {
  display: flex;
  gap: var(--spacing-050);
  align-items: center;

  margin: 0;

  font: var(--desktop-text-md-medium);
  color: var(--text-secondary);
}

.deposit-button {
  margin-top: var(--spacing-300);
  margin-bottom: var(--spacing-250);
}

.timer {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-025);
}

.timer-title {
  font: var(--desktop-text-xs-medium);
  color: var(--text-tertiary);
  text-align: center;
}

.deposit-modal {
  display: flex;
  flex-direction: column;

  width: 478px;
  min-height: 464px;

  background: radial-gradient(
      52.81% 47.84% at 12.58% 0%,
      rgb(255 91 39 / 60%) 4.24%,
      rgb(243 45 69 / 0%) 100%
    ),
    radial-gradient(
      106.54% 82% at 87.42% 0%,
      rgb(255 48 70 / 60%) 4.24%,
      rgb(38 38 49 / 0%) 72.61%
    ),
    var(--background-base, #111118);
  border-radius: var(--border-radius-200);
  box-shadow: 0 0 80px -8px #1d1d2a;

  &.mobile {
    width: 100%;
    padding-top: var(--spacing-250);

    .deposit-modal-wrapper {
      flex-grow: 1;
      height: auto;
      padding: var(--spacing-200);
      padding-top: 140px; /* stylelint-disable-line */
    }

    .title {
      font: var(--mobile-headline-semibold);
    }

    .reward-max {
      font: var(--mobile-text-medium);
    }

    .rolling-button {
      margin: var(--spacing-200) 0;
    }
  }
}

.close-button {
  all: unset;

  position: absolute;
  top: 4px;
  right: 4px;

  padding: var(--spacing-125);

  line-height: 0;
}
</style>
